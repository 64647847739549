import { combineReducers } from 'redux';
import currentUser from './currentUser'
import { USER_LOGOUT } from '../actions/actionCreators'
import info from './info'


const reducers = combineReducers({
    currentUser,
    info
});

const rootReducer = (state, action) => {
    if (action.type === USER_LOGOUT) {
        state = undefined
        console.log('Logged out. Store reset.')
    }

    return reducers(state, action);
};

export default rootReducer;
