import React from 'react'
import Header from '../components/Header'
import '../css/aboutscreen.css'
import '../css/aboutscreen-responsive.css'
import ReactWOW from 'react-wow';
import Footer from '../components/Footer';

const AboutScreen = () =>{
    return(
        <div className="aboutscreen">
            <Header />
            <div className="about-jumbotron"></div>
            <section className="about-section">
            <div className="title-wrapper">
                <ReactWOW animation='fadeIn' duration="800ms" >
                    <p className="title">About Us</p>
                </ReactWOW>
            </div>
                <ReactWOW animation='fadeIn' duration="1200ms" >
                    <p className="blurb">
                    Dashen Opticians is an ultramodern facility <br /> 
                    with 17 years of exceptional service <br />
                    experience in the optical industry.
                    </p>
                </ReactWOW>
                <ReactWOW animation='slideInLeft' duration="1000ms" >
                    <div className="divider" />
                </ReactWOW>
                <div className="text-wrapper">
                    <p>
                    As one of the largest independent optical centres in the country, we have established our
                    reputation in providing the highest quality products and services at very competitive
                    pricing and building superior customer relationship through the delivery of unrivaled value.
                    </p>

                    <p>
                    Our collective mission is to ensure the highest quality and most expedient delivery of
                    precision crafted eyewear to meet our clients’ needs.
                    </p>
                    <p>
                    We are experts at performing high-volume contracts for managed care organizations, one 
                    of such being the TRUST Hospital where we currently have outlets in 6 of their branches in Accra.
                    </p>
                </div>
            </section>
            <section className="our-partners-section">
            <div className="title-wrapper">
                <ReactWOW animation='fadeIn' duration="800ms" >
                    <p className="title" style={{marginBottom:'40px'}}>OUR PARTNERS</p>
                </ReactWOW>
                {/* <p className="title-blurb">Come experience the difference.</p> */}
            {/* <p className="title-description">Our range of services help you achieve<br /> the best eye sight possible.</p> */}
            </div>
            <div className="our-partners-grid">
                <ReactWOW animation='fadeIn' duration="800ms" >
                <div className="our-partners-grid-item">
                    <img src={require('../img/about/partners/apex.jpeg')} />
                </div>
                </ReactWOW>
                <ReactWOW animation='fadeIn' duration="1600ms" >
                <div className="our-partners-grid-item">
                    <img src={require('../img/about/partners/acacia.png')} />
                </div>
                </ReactWOW>
                <ReactWOW animation='fadeIn' duration="2400ms" >
                <div className="our-partners-grid-item">
                    <img src={require('../img/about/partners/nationwide.png')} />
                </div>
                </ReactWOW>
                <ReactWOW animation='fadeIn' duration="4000ms" >
                <div className="our-partners-grid-item">
                    <img src={require('../img/about/partners/Kaiser.png')} />
                </div>
                </ReactWOW>
                <ReactWOW animation='fadeIn' duration="4800ms" >
                <div className="our-partners-grid-item">
                    <img src={require('../img/about/partners/metropolitan.jpg')} />
                </div>
                </ReactWOW>
                <ReactWOW animation='fadeIn' duration="3200ms" >
                <div className="our-partners-grid-item">
                    <img src={require('../img/about/partners/glico.jpg')} />
                </div>
                </ReactWOW>
                <ReactWOW animation='fadeIn' duration="5600ms" >
                <div className="our-partners-grid-item">
                    <img src={require('../img/about/partners/mso.jpeg')} />
                </div>
                </ReactWOW>
                <ReactWOW animation='fadeIn' duration="6400ms" >
                <div className="our-partners-grid-item">
                    <img style={{width:"100%"}} src={require('../img/about/partners/premier.png')} />
                </div>
                </ReactWOW>
                <div className="our-partners-grid-item">
                </div>
            </div>
            </section>
            <Footer />
        </div>
    )
}

export default AboutScreen;