import React, {useState} from 'react'
import Loader from "../Loader"

const ContactCard = (props) =>{

    const [isLoading, setIsLoading] = useState(false)
    const [contactDetails, setContactDetails] = useState({
        name:"",
        email:"",
        message:""
    })

    const isValid = () => {
        //Verify firstname
        if(contactDetails.name.length <= 0){
            console.log("fn")
            return false
        }

        //verify password has been entered
        if(contactDetails.message.length <= 2){
            return false
        }

        //Verify Email has been entered
        if(contactDetails.email.length <= 0){
            return false
        }

        //Verify valid email form has been entered
        if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(contactDetails.email))){
            return false
        }

        return true;
    }

    const sendMessage = () =>{
        console.log(contactDetails)
    }

    const handleChange = (e) =>{
        setContactDetails({
            ...contactDetails,
            [e.target.name]:e.target.value
        })
    }

    return(
        <div className="contact-card">
            <div className="message-form">
                <p className="title">Send us a Message</p>
                <div className="form-field-wrapper">
                    <label>Your Full Name</label>
                    <input name="name" onChange={handleChange} value={contactDetails.name} type="text" />
                </div>
                <div className="form-field-wrapper">
                    <label>Your Email Address</label>
                    <input name="email" onChange={handleChange} value={contactDetails.email} type="email" />
                </div>
                <div className="form-field-wrapper">
                    <label>Message</label>
                    <textarea name="message" onChange={handleChange} value={contactDetails.message} rows="4"></textarea>
                </div>
                <button disabled={isLoading || !isValid()} onClick={sendMessage} >{isLoading?<Loader />: "Send Message"}</button>
            </div>
            <div className="contact-info">
                <p className="title">Contact Information</p>
                <div>
                    <p className="head-office">Head Office</p>
                    <ul>
                        <li>No. 5 Nyano Lane</li>
                        <li>info@dashenopticians.com</li>
                        <li>0205842212</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default ContactCard;