import React from 'react';
import { HashRouter, Routes, Route } from 'react-router-dom'
import HomeScreen from './containers/HomeScreen'
import { HOME, 
	ABOUT, 
	CONTACT, 
	BRANCHES, 
	STORE, 
	APPOINTMENTS, 
	LOGIN, 
	REGISTER,
	FORGOTTEN_PASSWORD,
	ORDERS,
	RESET_PASSWORD
} from './config/routes'
import ContactScreen from './containers/ContactScreen'
import './css/animate.css'
import './css/main.css'
import AboutScreen from './containers/AboutScreen';
import BranchesScreen from './containers/BranchesScreen';
import StoreScreen from './containers/StoreScreen'
import AppointmentsScreen from './containers/AppointmentsScreen'
import RegisterScreen from './containers/RegisterScreen'
import LoginScreen from './containers/LoginScreen'
import ForgottenPasswordScreen from './containers/ForgottenPasswordScreen'
import ResetPasswordScreen from './containers/ResetPasswordScreen'
import OrdersScreen from './containers/OrdersScreen'
import { Provider } from 'react-redux'
import store from './store/store'
import { PersistGate } from 'redux-persist/lib/integration/react'
import { persistStore } from 'redux-persist'
import InfoBar from './components/InfoBar'

function App() {

	const persistor = persistStore(store)

	return (
		<Provider store={store}>
			<PersistGate persistor={persistor}>
				<HashRouter>
					<Routes>
						<Route exact path={HOME} element={<HomeScreen/>} />
						<Route exact path={ABOUT} element={<AboutScreen/>} />
						<Route exact path={BRANCHES} element={<BranchesScreen/>} />
						<Route exact path={CONTACT} element={<ContactScreen/>} />
						<Route exact path={STORE} element={<StoreScreen/>} />
						<Route exact path={APPOINTMENTS} element={<AppointmentsScreen/>} />
						<Route exact path={REGISTER} element={<RegisterScreen/>} />
						<Route exact path={LOGIN} element={<LoginScreen/>} />
						{/* <Route exact path={FORGOTTEN_PASSWORD} element={ForgottenPasswordScreen} /> */}
						<Route exact path={ORDERS} element={<OrdersScreen/>} />
						{/* <Route exact path={RESET_PASSWORD} element={ResetPasswordScreen} /> */}
					</Routes>
				</HashRouter>
				<InfoBar />
			</PersistGate>
		</Provider>
	);
}

export default App;
