import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import AppointmentsCard from '../components/appointments/AppointmentsCard'
import "react-datepicker/dist/react-datepicker.css";
import "../css/appointmentsscreen.css"
import "../css/appointmentsscreen-responsive.css"

const AppointmentsScreen = () =>{
    return(
        <div>
            <Header />
            <div className="appointments-screen">
                <div className="left-section">
                    <p className="blurb">It's easy to make an appointment with Dashen Opticians</p>
                    <p className="subtext">We'll get back to you shortly after filling this form</p>
                    <AppointmentsCard />
                </div>
                {/* <div className="image"></div> */}
                <img className="image" src={require('../img/appointment/appointment-page.png')} />
            </div>
            <Footer />
        </div>
    )
}

export default AppointmentsScreen;