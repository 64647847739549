import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios';
import { showMessage } from "../../actions/actionCreators"

const baseUrl = process.env.REACT_APP_BASE_URL;


const PaymentMethodModal = ({isVisible, product, handleClose, selectedVariant}) =>{

	const dispatch = useDispatch();

	const userId = useSelector(({ currentUser: { id } }) => id)

	const handlePaymentOptionSelect = payment_method =>{
		const orderJson = {
			inventory_id: product.id,
			quantity: 1,
			ordered_by: userId,
			variant: selectedVariant,
			payment_method

		}

		axios.post(baseUrl + '/api/orders/initiate-payment', orderJson)
			.then(results => {
				// dispatch(showMessage("Order Made Successfully. You'll be contacted shortly!", "success"))
				// setIsLoading(false)
				dispatch(showMessage("Order Made Successfully. You'll be redirected to complete payment", "success"))
				window.location.replace(results.data.data.link);
				handleClose();
			})
			.catch(err => {
				dispatch(showMessage("Failed To Make Order. Please Try Again", 'error'))
				// setIsLoading(false);
				console.log("failed to make order", err)
			})
	}
	if(!isVisible){
		return null
	}
	return(
		<div className="payment-modal-wrapper modal-wrapper">
		<div className="impact-modal">
			<div className="impact-modal-header">
				<button onClick={handleClose} className="close-btn"><img src={require("../../img/close.png")}  /></button>
			</div>
			<div className="impact-modal-content">
				<h5>How would you like to pay</h5>
				<button className="payment-method-item" onClick={()=>handlePaymentOptionSelect('cash_payment')}>Cash Payment</button>
				<button className="payment-method-item" onClick={()=>handlePaymentOptionSelect('online_payment')}>Online Payment(Visa/Mastercard/Mobile Money etc.)</button>
			</div>
		</div>
	</div>
	)
}

export default PaymentMethodModal