import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import "../css/ordersscreen.css"
import "../css/ordersscreen-responsive.css"
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import moment from 'moment';
import { showMessage } from '../actions/actionCreators'

const baseUrl = process.env.REACT_APP_BASE_URL;

const OrdersScreen = () => {

	const [orders, setOrders] = useState([]);
	const [isLoading, setIsLoading] = useState(false)

	const userId = useSelector(({ currentUser: { id } }) => id);
	const dispatch = useDispatch();

	const showOrderItems = () => {
		if (isLoading) {
			return (
				<div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
					<i>Loading orders...</i>
				</div>
			)
		} else if (orders.length === 0) {
			return (
				<div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
					<i>You haven't made any orders yet</i>
				</div>
			)
		} else {
			return (
				<table className="orders-table">
					<thead>
						<tr>
							<td>Date Ordered</td>
							<td>Product</td>
							<td>Quantity</td>
							<td>Delivery Location</td>
							<td>Price</td>
							<td>Status</td>
						</tr>
					</thead>
					<tbody>
						{
							orders && orders.map(order => (
								<tr key={order.id}>
									<td>{moment(order.date_created).format('Do MMM YYYY')}</td>
									<td className="product-field"><img src={`${order.item_image_url !== 'undefined' ? order.item_image_url : require('../img/no-image.jpg')}`} /><p className="item-name">{order.item_name}</p></td>
									<td>{order.quantity}</td>
									<td>{order.client_location}</td>
									<td>GH&cent; {order.item_price}</td>
									<td>{order.status}</td>
								</tr>
							))
						}

					</tbody>
				</table>
			)
		}
	}

	const getUserOrders = () => {
		setIsLoading(true)
		axios.get(baseUrl + '/api/orders/users/' + userId)
			.then(result => {
				setIsLoading(false)
				setOrders(result.data)
			})
			.catch(err => {
				setIsLoading(false)
				dispatch(showMessage("Problem loading orders. Please refresh the page", "error"))
				console.log(err)
			})
	}

	useEffect(() => {
		getUserOrders()
	}, [])

	return (
		<div className="orders-screen">
			<Header />

			{showOrderItems()}
		</div>
	)
}

export default OrdersScreen;