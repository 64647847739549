export const HOME = '/'
export const ABOUT = '/about'
export const CONTACT = '/contact'
export const STORE = '/store'
export const BRANCHES = '/branches'
export const APPOINTMENTS = '/appointments'
export const LOGIN = '/login'
export const REGISTER = '/register'
export const FORGOTTEN_PASSWORD = '/forgotten-password'
export const ORDERS = '/orders'
export const RESET_PASSWORD = '/reset-password'