import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Loader from '../Loader'

import classnames from 'classnames';
import { LocalConvenienceStoreOutlined } from '@material-ui/icons';
import ReactImageMagnify from 'react-image-magnify';

const baseUrl = process.env.REACT_APP_BASE_URL;

const PurchaseSummaryModal = ({ isVisible, showPaymentMethodModal, selectedVariant, setSelectedVariant, product: { brand, images, variants, name, price, description }, handleClose }) => {

	const isLoggedIn = useSelector(({ currentUser: { loggedIn } }) => loggedIn);

	// const userId = useSelector(({ currentUser: { id } }) => id)

	// const dispatch = useDispatch();

	const [isLoading, setIsLoading] = useState(false);

	const [selectedImage, setSelectedImage] = useState(null);

	useEffect(() => {
		//set variant
		if (variants && variants.length > 0) {
			setSelectedVariant(variants[0])
			console.log("selected variant", variants[0])
		}
	}, [variants])

	useEffect(() => {
		//set image
		if (images && images.length > 0) {
			setSelectedImage(images[0])
			console.log("selected image", selectedImage)
		}
	}, [images])

	const placeOrder = () => {
		setIsLoading(true);
		showPaymentMethodModal();

		// const orderJson = {
		// 	inventory_id: product.id,
		// 	quantity: 1,
		// 	ordered_by: userId,
		// 	variant: selectedVariant,

		// }

		// // axios.post(baseUrl + '/api/orders/initiate-payment', orderJson)
		// // 	.then(results => {
		// // 		// dispatch(showMessage("Order Made Successfully. You'll be contacted shortly!", "success"))
		// // 		setIsLoading(false)
		// // 		dispatch(showMessage("Order Made Successfully. You'll be redirected to complete payment", "success"))
		// // 		window.location.replace(results.data.data.link);
		// // 		handleClose()
		// // 	})
		// // 	.catch(err => {
		// // 		dispatch(showMessage("Failed To Make Order. Please Try Again", "error"))
		// // 		setIsLoading(false);
		// // 		console.log(err)
		// // 	})
	}

	if (!isVisible) {
		return null
	}
	return (
		<div className="purchase-summary-modal-overlay">
			<div className="modal">
				<div className="close-modal-btn-wrapper">
					<div onClick={handleClose} className="close-modal-btn">
						<img src={require('../../img/close.png')} />
					</div>
				</div>
				<div className="purchase-modal-container">
					<div className="purchase-modal-image-panel">
						<div className="active-image">
							<ReactImageMagnify {...{
								enlargedImagePosition: 'over',
								smallImage: {
									isFluidWidth: true,
									src: selectedImage && selectedImage.image_url !== "undefined" ? selectedImage.image_url : require('../../img/no-image.jpg')
								},
								largeImage: {
									src: selectedImage && selectedImage.image_url !== "undefined" ? selectedImage.image_url : require('../../img/no-image.jpg'),
									width: 1430,
									height: 953
								}
							}} />
							{/* <img className="item-image" src={selectedImage && selectedImage.image_url !== "undefined" ? selectedImage.image_url : require('../../img/no-image.jpg')} /> */}
						</div>
						<div className="image-list">
							{
								images && images.map((image, index) => <div onClick={() => setSelectedImage(image)} key={image.id} className={classnames({ "image-list-item": true, "active": (selectedImage && selectedImage.id == image.id) })} style={{ backgroundImage: `url(${image.image_url})` }}></div>)
							}
						</div>
					</div>
					<div className="purchase-modal-detail-panel">
						{
							brand && (<p className="brand">{brand}</p>)
						}
						{
							name && (<p className="name">{name}</p>)
						}
						{/* <p className="description">{description ? description : <i>No Description Available</i>}</p> */}
						<p className="price">GH&cent; {selectedVariant ? (selectedVariant.price ? selectedVariant.price : price) : price}</p>

						<div className="divider" />

						<div className="variants-wrapper">
							<p style={{ fontSize: '13px' }}>Variant: {selectedVariant ? selectedVariant.name : null} </p>
							<div className="image-list">
								{
									variants && variants.map(variant => <div onClick={() => setSelectedVariant(variant)} key={variant.id} className={classnames({ "image-list-item": true, "active": (selectedVariant && selectedVariant.id == variant.id) })} style={{ backgroundImage: `url(${variant.image_url})` }}></div>)
								}
							</div>
						</div>

						<p className="price price-responsive">GH&cent; {selectedVariant ? (selectedVariant.price ? selectedVariant.price : price) : price}</p>

						<button disabled={!isLoggedIn || isLoading} onClick={placeOrder} className="place-order-btn">
							{
								!isLoggedIn ? "Please Login First" : isLoading ? <Loader /> : "Proceed To Payment"
							}
						</button>
					</div>
				</div>


			</div>
		</div >
	)
}

export default PurchaseSummaryModal;