// import axios from 'axios';
// import sha1 from 'sha1'

// const baseUrl = 'http://localhost:3010'
export const SET_USER = 'SET_USER'
export const USER_LOGOUT = 'USER_LOGOUT'

export const setCurrentUser = (user) => dispatch =>{
    dispatch({
        type:SET_USER,
        payload:user
    })
}

export const doLogout = () => dispatch => {
    console.log('do logout')
    dispatch({
        type:USER_LOGOUT
    })
}

//Message 
export const SHOW_MESSAGE = 'SHOW_MESSAGE'
export const HIDE_MESSAGE = 'HIDE_MESSAGE'

export const showMessage = (message, type) => dispatch => {
    console.log("show message", message, type)
	dispatch ({
		type: SHOW_MESSAGE,
		payload: {message, type}
	})
}

export const hideMessage = () => dispatch => {
	dispatch ({
		type: HIDE_MESSAGE
	})
}