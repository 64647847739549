import React from 'react'
import Header from '../components/Header';
import Jumbotron from '../components/home/Jumbotron';
import WhatWeDo from '../components/home/WhatWeDo';
import OurTeam from '../components/home/OurTeam';
import '../css/homescreen.css'
import '../css/homescreen-responsive.css'
import {STORE, APPOINTMENTS} from '../config/routes'
import {Link} from 'react-router-dom'
import ReactWOW from 'react-wow'
import Footer from '../components/Footer';

const HomeScreen = () => {
    return (
        <div className="homescreen">
            <Header />
            <div style={{width:'100%', boxSizing:'border-box'}}>
                <Jumbotron />
                <WhatWeDo />
                <OurTeam />
                <section className="shop-now" style={{padding:'0px'}}>
                    <div className="shop-now-grid">
                        <div className="image"></div>
                        <div className="text">
                            <p className="title">ONLINE SHOP</p>
                            <p className="tagline">Time to purchase your <br />next favorite pair <br />of glasses.</p>
                            <ReactWOW animation='fadeInUp' duration="1200ms" >
                                <Link to={STORE}><button>Shop Now</button></Link>
                            </ReactWOW>
                        </div>
                    </div>
                </section>
                <section className="book-appointment" style={{padding:'50px'}}>
                    <div className="book-appointment-grid">
                        <div className="text-wrapper">
                            <div className="text">
                                <p className="title">APPOINTMENT</p>
                                <p className="tagline">Come talk to <br />our experts to see how <br />we can serve you.</p>
                                <ReactWOW animation='fadeInUp' duration="1200ms" >
                                    <Link to={APPOINTMENTS}>
                                        <button>Book Appointment</button>
                                    </Link>
                                </ReactWOW>
                            </div>
                        </div>
                        <div className="image"></div>
                    </div>
                </section>
            </div>
            <Footer />
        </div>
    )
}

export default HomeScreen;