
import initialState from '../store/initialState';
import {SET_USER} from '../actions/actionCreators'

export default function currentUser(state = { ...initialState.currentUser }, action) {
    switch (action.type) {

        case SET_USER:
            return {
                ...state,
                ...action.payload,
                loggedIn:true
            }

        default:
            return state;
    }
}
