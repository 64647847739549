import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Header from '../components/Header';
import Footer from '../components/Footer'
import "../css/formone.css"
import { Link,useNavigate } from 'react-router-dom';
import { LOGIN, FORGOTTEN_PASSWORD } from '../config/routes'
import Loader from '../components/Loader';
import axios from 'axios';
import sha1 from 'sha1'
import { showMessage } from '../actions/actionCreators'

const baseUrl = process.env.REACT_APP_BASE_URL;

const RegisterScreen = (props) => {

	const [isLoading, setIsLoading] = useState(false);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const emptyForm = {
		firstname: "",
		lastname: "",
		phone: "",
		email: "",
		password: "",
		location: ""
	}

	const [user, setUser] = useState(emptyForm)

	const isValid = () => {
		//Verify firstname
		if (user.firstname.length <= 0) {
			return false
		}
		//Verify lastname
		if (user.lastname.length <= 0) {
			return false
		}
		//Verify phone
		if (user.phone.length !== 10) {
			return false
		}

		//verify phone number is a number
		if (isNaN(user.phone)) {
			return false
		}

		//verify password has been entered
		if (user.password.length <= 0) {
			return false
		}

		//Verify Email has been entered
		if (user.email.length <= 0) {
			return false
		}

		//Verify Delivery location
		if (user.location.length <= 0) {
			return false
		}

		//Verify valid email form has been entered
		if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(user.email))) {
			return false
		}

		return true;
	}

	const registerUser = () => {
		setIsLoading(true);

		if (!isValid()) {
			return false
		}

		const userJson = {
			...user,
			password: sha1(user.password)
		}

		axios.post(baseUrl + '/api/users/', userJson)
			.then(results => {
				setIsLoading(false);
				dispatch(showMessage("Account Created Successfully!", "success"))
				// setUser(emptyForm)
				navigate(LOGIN)
				console.log(results)
			})
			.catch(err => {
				console.log(err)
				let message = "Couldn't Create Account. Please Try Again";
				if(err.response && err.response.data && err.response.data.message){
					message = err.response.data.message
				}
				setIsLoading(false);
				dispatch(showMessage(message, "error"))
			})

	}

	const handleChange = (e) => {
		setUser({
			...user,
			[e.target.name]: e.target.value
		})
	}

	// useEffect(()=>{
	//     console.log(user)
	// },[user])


	return (
		<div>
			<Header />
			<div className="form-one">
				<div className="form-wrapper">
					<p className="title">Create Your Account</p>
					<div className="input-row">
						<input style={{ textTransform: 'capitalize' }} name="firstname" value={user.firstname} onChange={handleChange} placeholder="First Name" type="text" />
						<input style={{ textTransform: 'capitalize' }} name="lastname" value={user.lastname} onChange={handleChange} placeholder="Last Name" type="text" />
					</div>
					<form autocomplete="off">
						<input name="phone" value={user.phone} onChange={handleChange} placeholder="Phone Number" type="text" autocomplete="off"/>
						<input name="email" value={user.email} onChange={handleChange} placeholder="Email Address" type="text" autocomplete="off"/>
						<input name="location" value={user.location} onChange={handleChange} placeholder="Delivery Location For Orders" type="text" autocomplete="off"/>
						<input name="password" value={user.password} onChange={handleChange} placeholder="Password" type="password" autocomplete="new-password"/>
					</form>
					<button disabled={isLoading || !isValid()} onClick={registerUser} >{isLoading ? <Loader /> : "Create Account"}</button>
					<div className="lower-text-wrapper">
						<p>Already have an account?&nbsp;</p><Link to={LOGIN}>Sign In</Link>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	)
}

export default RegisterScreen;