import initialState from '../store/initialState'
import {
    SHOW_MESSAGE,
    HIDE_MESSAGE
} from '../actions/actionCreators'

export default function info(state = { ...initialState.info }, action) {
    switch (action.type) {
        case SHOW_MESSAGE:
            return {
                ...state,
                message: action.payload.message,
                type:action.payload.type,
                open:true
            }

        case HIDE_MESSAGE:
            return {
                ...state,
                message: "",
                open: false
            }
        default:
            return state
    }
}
