import React, { useEffect } from 'react'
import '../css/header.css'
import {HOME, ABOUT, STORE, BRANCHES, CONTACT, LOGIN, APPOINTMENTS} from '../config/routes';
import {Link} from 'react-router-dom'
import { useSelector } from 'react-redux';
import UserAccount from './UserAccount'

const Header = () => {

    const loggedIn = useSelector(({currentUser:{loggedIn}})=>loggedIn)

    useEffect(()=>{
        window.scrollTo(0,0);
        
        window.addEventListener('scroll', ()=>{
            const header = document.getElementById('header')

            if(window.pageYOffset > 20){
                header.style.backgroundColor = 'white';
                header.style.boxShadow = "0 0 10px 0 rgba(0,0,0,.1)";
            }else{
                header.style.backgroundColor = "transparent"
                header.style.boxShadow = "none";
            }
        })
    },[])

    const openMenu = () =>{
        const sideMenu = document.getElementById('sidemenu');
        sideMenu.style.boxShadow = "0 0 10px 0 rgba(0,0,0,.1)";
        sideMenu.style.height = '110vh';
    }
    
    const closeMenu = () =>{
        const sideMenu = document.getElementById('sidemenu');
        
        sideMenu.style.boxShadow = "transparent";
        sideMenu.style.height = '0vh';
    }

    return(
        <header className="header" id="header">
            <Link to={HOME}>
                <img className="logo" src={require('../img/logo.png')}/>
            </Link>
            <nav className="nav-desktop">
                <Link to={ABOUT}>
                    About
                </Link>
                <Link to={STORE}>
                    Store
                </Link>
                <Link to={BRANCHES}>
                    Branches
                </Link>
                <Link to={CONTACT}>
                    Contact
                </Link>
                <Link to={APPOINTMENTS}>
                    Appointment
                </Link>
                {loggedIn?<UserAccount />:(
                    <Link to={LOGIN}>
                        Login
                    </Link>
                )}
            </nav>
            <button className="sidemenu-btn" onClick={openMenu} >
                <img src={require('../img/menu.png')}/>
            </button>
            <div className="sidemenu" id="sidemenu">
                <div className="close-btn-wrapper">
                    <div className="close-btn" onClick={closeMenu}>
                        <img src={require('../img/close.png')}/>
                    </div>
                </div>
                <nav className="nav-mobile">
                    <Link to={ABOUT}>
                        About
                    </Link>
                    <Link to={STORE}>
                        Store
                    </Link>
                    <Link to={BRANCHES}>
                        Branches
                    </Link>
                    <Link to={CONTACT}>
                        Contact
                    </Link>
                    <Link to={APPOINTMENTS}>
                        Appointment
                    </Link>
                    {loggedIn?<UserAccount />:(
                        <Link to={LOGIN}>
                            Login
                        </Link>
                    )}
                </nav>
            </div>
        </header>
    )
}

export default Header;