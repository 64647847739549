import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import Axios from 'axios';
import Loader from '../Loader';
import moment from 'moment';
import { showMessage } from '../../actions/actionCreators'
import { useDispatch } from 'react-redux'

const baseUrl = process.env.REACT_APP_BASE_URL;

const AppointmentsCard = () => {

	const emptyAppointment = {
		firstname: "",
		lastname: "",
		phone: "",
		email: "",
		branch_id: 1,
	}

	const [startDate, setStartDate] = useState(new Date());
	const [branches, setBranches] = useState([])
	const [isLoading, setIsLoading] = useState(false);
	const [appointment, setAppointment] = useState(emptyAppointment)

	const dispatch = useDispatch()

	const handleChange = (e) => {
		setAppointment({
			...appointment,
			[e.target.name]: e.target.value
		})
	}

	const isValid = () => {
		//Verify firstname
		if (appointment.firstname.length <= 0) {
			return false
		}

		//Verify lastname
		if (appointment.lastname.length <= 0) {
			return false
		}

		//Verify Email has been entered
		if (appointment.email.length <= 0) {
			return false
		}

		//Verify valid email form has been entered
		if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(appointment.email))) {
			return false
		}

		//Verify phone
		if (appointment.phone.length !== 10) {
			return false
		}

		//verify phone number is a number
		if (isNaN(appointment.phone)) {
			return false
		}

		return true;
	}


	const getBranches = () => {
		Axios.get(baseUrl + '/api/branches')
			.then(results => {
				const branches = results.data;

				setBranches(branches)
				// showMessage("Appointment Create", "success")
			})
			.catch(err => {
				dispatch(showMessage("Error loading branches list. Please refresh page.", "error"))
				console.log(err)
			})
	}

	const createAppointment = () => {
		setIsLoading(true);

		const appointmentJson = {
			...appointment,
			client_name: appointment.firstname + " " + appointment.lastname,
			appointment_datetime: moment(startDate).format('YYYY-MM-DD HH:mm:ss')
		}

		Axios.post(baseUrl + '/api/appointments', appointmentJson)
			.then(results => {
				setIsLoading(false);
				console.log(results)
				dispatch(showMessage("Appointment Created Successfully", "success"))
				setAppointment(emptyAppointment)
			})
			.catch(err => {
				setIsLoading(false);
				dispatch(showMessage("Failed Creating Appointment", "error"))
				console.log(err)
			})
	}

	useEffect(() => {
		getBranches()
	}, [])

	return (
		<div className="appointments-card">
			<div className="form">
				<div className="input-row">
					<div className="form-field-wrapper">
						<label>Your First Name</label>
						<input name="firstname" value={appointment.firstname} onChange={handleChange} style={{ textTransform: 'capitalize' }} type="text" />
					</div>
					<div className="form-field-wrapper">
						<label>Your Last Name</label>
						<input name="lastname" value={appointment.lastname} onChange={handleChange} style={{ textTransform: 'capitalize' }} type="text" />
					</div>

				</div>
				<div className="form-field-wrapper">
					<label>Your Phone Number</label>
					<input name="phone" value={appointment.phone} onChange={handleChange} type="tel" />
				</div>
				<div className="form-field-wrapper">
					<label>Your Email Address</label>
					<input name="email" value={appointment.email} onChange={handleChange} type="email" />
				</div>
				{branches ? (
					<div className="form-field-wrapper">
						<label>What Branch Would You Be Visiting?</label>
						<select name="branch_id" value={appointment.branch} onChange={handleChange}>
							{
								branches.map(branch => (
									<option key={branch.id} value={branch.id}>{branch.name}</option>
								))
							}
						</select>
					</div>

				) : (<Loader />)}
				{/* <input placeholder="Preferred Branch" /> */}
				<div className="form-field-wrapper">
					<label>When Would You Like To Have Your Appointment?</label>
					<DatePicker
						selected={startDate}
						onChange={date => setStartDate(date)}
						showTimeSelect
						timeFormat="HH:mm"
						timeIntervals={15}
						timeCaption="time"
						dateFormat="MMMM d, yyyy h:mm aa"
					/>
				</div>
				<button
					disabled={isLoading || !isValid()}
					onClick={createAppointment}
					className="book-appointment-btn">
					{isLoading ? <Loader /> : "Create Appointment"}
				</button>
			</div>
		</div>
	)
}

export default AppointmentsCard