import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {doLogout, showMessage} from '../actions/actionCreators'
import {ORDERS} from '../config/routes'
import {Link} from 'react-router-dom'
 
const UserAccount = () =>{

    const dispatch = useDispatch();

    const accountUser = useSelector(({currentUser:{firstname, lastname}})=>({firstname, lastname}))

    const handleAvatarClick = () => {
        toggleAccountMenu()
    }

    const toggleAccountMenu = () => {
        const accountMenu = document.getElementById('account-menu');

        if(accountMenu.style.height === "0px" ){
            accountMenu.style.height = '88px';
        }else{
            accountMenu.style.height = '0px';
        }
    }

    const handleLogout = () =>{
        toggleAccountMenu()
        
        dispatch(doLogout())
        dispatch(showMessage("Logged Out", "info"))
    }

    const getUserInitials = () => {
        if(!accountUser.firstname || !accountUser.lastname){
            return "AU"
        }
        return accountUser.firstname.toUpperCase().substring(0, 1) + accountUser.lastname.toUpperCase().substring(0, 1)
    }

    return(
        <div className="account-wrapper">
            <div onClick={handleAvatarClick} className="account-avatar">{getUserInitials()}</div>
            <div id="account-menu" className="account-menu">
                    <div className="account-menu-item">
                        <Link to={ORDERS}>
                            My Orders
                        </Link>
                    </div>
                {/* <div>Edit Account</div> */}
                <div onClick={handleLogout} className="account-menu-item">Logout</div>
            </div>
        </div>
    )
}

export default UserAccount;