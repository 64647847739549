import React from 'react'

const StoreItem = ({ product, product: { brand, name, price, images }, handleClick }) => {
	const storeItemImage = images[0] && images[0].image_url !== "undefined" ? images[0].image_url : require('../../img/no-image.jpg');
	return (
		<div className="store-grid-item" onClick={handleClick}>
			{/* <div className='item-image' style={{backgroundImage:`url("${images[0] && images[0].image_url !== "undefined"?images[0].image_url:require('../../img/no-image.jpg')}")`}}> */}
			<img className='item-image' src={storeItemImage} />
			{/* <p className="brand">{brand}</p> */}
			{/* <p className="name">{name}</p> */}
			<p className="price"><span className="price-label">Price</span>GH&cent;{price}</p>
		</div>
	)
}

export default StoreItem