import React, {useEffect, useState} from 'react'
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { showMessage } from "../../actions/actionCreators"



const baseUrl = process.env.REACT_APP_BASE_URL;

const VerifyPaymentModal = () => {

	const [searchParams, setSearchParams] = useSearchParams()
	const dispatch = useDispatch();

	const [isVerifyPaymentModalVisible, setIsVerifyPaymentModalVisible] = useState(false)
	const [isAnimatingModal, setIsAnimatingModal] = useState(false);
	const [modalText, setModalText] = useState("Confirming Payment");
	const [verificationStatus, setVerificationStatus] = useState('pending')

	const showModalIcon = () =>{
		switch(verificationStatus){
			case 'failed':
				return <img src={require("../../img/failed-icon.png")} />
			case 'success':
				return <img src={require("../../img/success-icon.png")} />
			default:
				return <img src={require("../../img/loading.gif")} />
		}
	}

	const hideVerifyPaymentModal = () =>{
		setIsAnimatingModal(true)
		setTimeout(()=>{
			setIsVerifyPaymentModalVisible(false)
		},500)
	}

	useEffect(()=>{
		setIsAnimatingModal(false)
	},[])

	useEffect(() => {
		const tx_ref = searchParams.get('tx_ref');
		const status = searchParams.get('status');
		const transaction_id = searchParams.get('transaction_id');

		if(tx_ref && status && transaction_id){
			setIsVerifyPaymentModalVisible(true);

			const validationJSON = {
				transaction_id,
				status,
				tx_ref
			}

			axios.post(baseUrl + '/api/orders/validate-payment', validationJSON)
				.then(results => {
					if(results.data && results.data.status == 'success'){
						console.log("success")
						dispatch(showMessage("Order Made Successfully!", "success"))

						//show success on modal, wait then hide modal
						setModalText("Order created successfully!")
						setVerificationStatus('success')

						setTimeout(()=>{
							hideVerifyPaymentModal();
						},5000)
					}
					if(results.data && results.data.status == 'error'){
						console.log("failed")
						dispatch(showMessage("Failed to validate transaction.", "error"))

						//show failed on modal, wait then hide modal
						setModalText("Failed to place order.")
						setVerificationStatus('failed')
					}
				})
				.catch(err => {
					dispatch(showMessage("Failed To Make Order. Please Try Again", "error"))

					//show failed on modal, wait then hide modal
					setModalText("Failed to place order.")
					setVerificationStatus('failed')
					console.log(err)
				})
			// $.ajax({
			// 	url:baseURL + "/appointments/validate-payment",
			// 	method:"POST",
			// 	data:validationJSON,
			// 	success: function(response){
			// 		$('.verifying-payment-card').empty();
			// 		$('.verifying-payment-card').append('<p class="title">Payment Verified</p>');
			// 		// $('.verifying-payment-card').append('<p class="description">You should be receiving an email</p>');
			// 		$('.verifying-payment-card').append('<img src="./images/success-icon.png" />');
			// 		// $('.verifying-payment-card').append('<div class="homepage-wrapper"><a href="https://impactclinic.com.gh/">Return to Homepage</a></div>');
			// 		setTimeout(function(){
			// 			window.location.href
			// 			// console.log('redirecting');
			// 			$.redirect('/', {'tx_ref':tx_ref}, 'GET');
			// 		},2000)
			// 		console.log("response", response)
			// 	},
			// 	error: function(err){
			// 		$('.verifying-payment-card').empty();
			// 		$('.verifying-payment-card').append('<p class="title">Verification Failed</p>');
			// 		$('.verifying-payment-card').append('<p class="description">There was a problem verifying your payment</p>');
			// 		$('.verifying-payment-card').append('<img src="./images/failed-icon.png" />');
			// 		console.log("error", err)
			// 	},

			// })
		}

	}, [])

	if (!isVerifyPaymentModalVisible) {
		return null
	}
	return (
		<div className={isAnimatingModal? "verifying-payment-wrapper animate-verification-modal": "verifying-payment-wrapper"}>
			<div className="verifying-payment-card">
				<p className="title">{modalText}</p>
				{showModalIcon()}
			</div>
			<div className="contact-info">
				Having problems? Call <a href="tel:0200153279">020-584-2212</a> or send an email to <a href="mailto:dashen_opticians@yahoo.com">dashen_opticians@yahoo.com</a>
			</div>
		</div>

		// <div className="purchase-summary-modal-overlay">
		// 	<div className="modal" style={{width:'500px', height:'500px'}}>
		// 		<div className="close-modal-btn-wrapper">
		// 			<div onClick={handleClose} className="close-modal-btn">
		// 				<img src={require('../../img/close.png')} />
		// 			</div>
		// 		</div>
		// 		<div className="purchase-modal-container">


		// 		</div>


		// 	</div>
		// </div >
	)
}

export default VerifyPaymentModal;