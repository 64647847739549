import React, {useState} from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer'
import ContactCard from '../components/contact/ContactCard'
import '../css/contactscreen.css'
import '../css/contactscreen-responsive.css'
import ReactWOW from 'react-wow'


const ContactScreen = () =>{
    return(
        <div className="contact-screen">
            <Header />
            <div className="jumbotron">
                <div className="watermark"></div>
            </div>
            <div className="wrapper">
                <div className="main-section-wrapper">
                    <div className="top">
                        <ReactWOW animation='fadeInUp' duration="1300ms" >
                            <p className="get-in-touch">Get In Touch</p>
                        </ReactWOW>
                        <ReactWOW animation='fadeInUp' duration="1500ms" >
                            <p className="subtext">If you have any questions, need a little guidance,<br /> or just want to say hi, we're here for you.</p>
                        </ReactWOW>
                    </div>
                    <ContactCard />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ContactScreen;