import React, {useState, useEffect} from 'react';
import Header from '../components/Header';
import BranchCard from '../components/branches/BranchCard'

import "../css/branchesscreen.css"
import "../css/branchesscreen-responsive.css"
import Footer from '../components/Footer';

const BranchesScreen = () =>{

    const branches = [
        {
            name:"Asylum Down Branch",
            location:"Head Office No. 5 Nyano Lane",
            phone:'0205842212',
            map:'<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63537.69587616998!2d-0.22260248676485664!3d5.5513021422636735!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdf9a723cbde2cb%3A0x4068821fa49c820d!2sDashen%20Opticians%20(%20Asylum%20Down)!5e0!3m2!1sen!2sgh!4v1584379027100!5m2!1sen!2sgh" width="100%" height="650" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>'
        },
        {
            name:"North Ridge Branch",
            location:"7 North Ridge Cresent, Next to old KLM office",
            phone:'0203300660',
            map:'<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3970.9576059269916!2d-0.20018868543871166!3d5.5732865350256064!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdf9a6e26240c97%3A0x87eec89e8438475d!2sDashen%20Opticians-North%20Ridge!5e0!3m2!1sen!2sgh!4v1584379462889!5m2!1sen!2sgh" width="100%" height="650" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>'
        },
        {
            name:"East Legon Branch",
            location:"Main East Legon Road(Lagos Avenue), Next to old Mensvic Hotel",
            phone:'0203300649',
            map:'<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3970.5159631777306!2d-0.17315758543864312!3d5.638213134385308!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdf9b66f962b82b%3A0x6fdca3f9ae501ee8!2sDashen%20Opticians!5e0!3m2!1sen!2sgh!4v1584474254715!5m2!1sen!2sgh" width="100%" height="650" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>'
        },
        {
            name:"Spintex Branch",
            location:"Community 18 Junction Spintex Road, Baatsona",
            phone:'0203300653',
            map:'<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3970.6043752693145!2d-0.07879758543861998!3d5.625275334513452!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdf841f84f9dd4d%3A0x89c14eef750061a!2sDashen%20Opticians!5e0!3m2!1sen!2sgh!4v1584474358956!5m2!1sen!2sgh" width="100%" height="650" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>'
        },
        {
            name:"Cantoments Branch",
            location:"51 Third Rangoon Cl, Accra",
            phone:'0201690324',
            map:'<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15883.839846459616!2d-0.1814119!3d5.5729382!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdf9b1fbeaf4a6b%3A0xb03f3b58ab9d141f!2sDashen%20Opticians%20(Cantoments)!5e0!3m2!1sen!2sgh!4v1685653125233!5m2!1sen!2sgh" width="100%" height="650" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>'
        },
    ]

    const [branch, setBranch] = useState(branches[0])

    return(
        <div>
            <Header />
            <div className="branches-screen">
                <div className="branch-cards">
                    {
                        branches.map((item, index)=>(
                            <BranchCard
                                isActive={branch.name === item.name}
                                key={index} 
                                handleOnClick={()=>setBranch(item)} 
                                branch={item} 
                            />
                        ))
                    }
                </div>
                <div className="map" dangerouslySetInnerHTML={{__html:branch.map}}></div>
            </div>
            <Footer />
        </div>
    )
}

export default BranchesScreen;