import React from 'react'
import {STORE, APPOINTMENTS} from '../../config/routes'
import {Link} from 'react-router-dom'
import ReactWOW from 'react-wow'

const Jumbotron = () =>{
    return(
        <div className="jumbotron">
            <div className="text-wrapper">
                <div className="text">
                    <ReactWOW animation='fadeInUp' duration="1000ms" >
                        <p className="tagline">Give your eyes<br />the care they deserve.</p>
                    </ReactWOW>
                    <ReactWOW animation='fadeIn' delay="500ms" duration="1500ms">
                        <p className="subtext">Premium eye treatment is a click away.</p>
                    </ReactWOW>
                    <ReactWOW animation='fadeInUp' delay="100ms" duration="1200ms">
                        <div className="button-wrapper">
                            <Link to={STORE}><button>Shop Now</button></Link>
                            <Link to={APPOINTMENTS}><button>Book Appointment</button></Link>
                        </div>
                    </ReactWOW>
                </div>
            </div>
            <div className="image"></div>
        </div>
    )
}

export default Jumbotron