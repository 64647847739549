import React from 'react'
import ReactWOW from 'react-wow';

const WhatWeDo = () => {
    return (
        <section className="what-we-do" id="offerings">
        <div className="title-wrapper">
            <ReactWOW animation='fadeIn' duration="800ms" >
                <p className="title">OUR OFFERINGS</p>
            </ReactWOW>
            <ReactWOW animation='fadeInUp' duration="1200ms" >
                <p className="title-blurb">Come experience the difference.</p>
            </ReactWOW>
            <ReactWOW animation='fadeInUp' duration="1500ms" >
                <p className="title-description">Our range of offerings help you achieve<br /> the best eye sight possible.</p>
            </ReactWOW>
        </div>
        <div className="what-we-do-grid">
            <div className="what-we-do-grid-item">
                <div className="image" style={{backgroundImage:`url(${require("../../img/home/word-class-equipment.jpg")})`}}></div>
                <div className="text-wrapper">
                    <p className="title">Worldclass Staff<br /> and Equipment</p>
                    <p className="description">This enables us to consistently provide<br /> high-quality Optical services.</p>
                </div>
            </div>
            <div className="what-we-do-grid-item">
                <div className="image" style={{backgroundImage:`url(${require("../../img/home/vendors.jpg")})`}}></div>
                <div className="text-wrapper">
                    <p className="title">Close Relationship<br /> With Vendors</p>
                    <p className="description">This enables us to offer a
                        wide variety of<br /> products and timely repair and replacements, <br />if necessary.</p>
                </div>
            </div>
            <div className="what-we-do-grid-item">
                <div className="image" style={{backgroundImage:`url(${require("../../img/home/quality-control.jpg")})`}}></div>
                <div className="text-wrapper">
                    <p className="title">Rigorous Quality Control<br /> Program</p>
                    <p className="description">This enables us to prevent errors before they<br /> occur as
well as take immediate corrective actions in the rare cases when they do.</p>
                </div>
            </div>
        </div>
    </section>
    )
}

export default WhatWeDo;