import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import "../css/formone.css"
import { Link, useNavigate } from 'react-router-dom';
import { REGISTER, FORGOTTEN_PASSWORD, HOME } from '../config/routes'
import Footer from '../components/Footer';
import Loader from '../components/Loader';
import axios from 'axios';
import sha1 from 'sha1'
import { useDispatch } from 'react-redux'
import { setCurrentUser, showMessage } from '../actions/actionCreators'

const baseUrl = process.env.REACT_APP_BASE_URL;

const LoginScreen = (props) => {

	const [user, setUser] = useState({
		email: "",
		password: ""
	})
	const [isLoading, setIsLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState(null)
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const isValid = () => {
		//verify password has been entered
		if (user.password.length <= 0) {
			return false
		}

		//Verify Email has been entered
		if (user.email.length <= 0) {
			return false
		}

		//Verify valid email form has been entered
		if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(user.email))) {
			return false
		}

		return true;
	}

	const handleChange = (e) => {
		setUser({
			...user,
			[e.target.name]: e.target.value
		})
	}

	const authenticateUser = () => {
		if (!isValid()) {
			return true;
		}

		setIsLoading(true);
		setErrorMessage(null)

		const userJson = {
			...user,
			password: sha1(user.password)
		}

		axios.post(baseUrl + '/api/users/authenticate', userJson)
			.then(results => {
				if (results.data.length === 0) {
					//authentication failed. show error message
					setErrorMessage("Email or Password Incorrect")
				} else {
					setErrorMessage(null)
					//authentication successfull. store login information.
					const returnedUser = results.data[0];
					// dispatch(setAuthToken(token))
					dispatch(setCurrentUser(returnedUser))
					dispatch(showMessage("Login Successful!", 'success'))

					navigate(HOME)
				}
				setIsLoading(false);
			})
			.catch(err => {
				setErrorMessage("There was a problem logging in please try again later")
				setIsLoading(false);
				console.log(err)
			})
	}

	// useEffect(()=>{
	//     console.log(user)
	// },[user])

	return (
		<div>
			<Header />
			<div className="form-one">
				<div className="form-wrapper">
					<p className="title">Sign In</p>
					<input onChange={handleChange} name="email" value={user.email} placeholder="Email" type="text" />
					<input onChange={handleChange} name="password" value={user.password} placeholder="Password" type="password" />
					<button disabled={isLoading || !isValid()} onClick={authenticateUser}>{isLoading ? <Loader /> : "Sign In"}</button>
					{errorMessage ? <p className="error-message">{errorMessage}</p> : null}
					<div className="lower-text-wrapper">
						<p>Don't have an account?&nbsp;</p><Link to={REGISTER}>Create one</Link>
					</div>
					{/* <Link className="forgotten-password" to={FORGOTTEN_PASSWORD}>Forgotten Password?</Link> */}
				</div>
			</div>
			<Footer />
		</div>
	)
}

export default LoginScreen;