import React from 'react'

const BranchCard = ({branch, handleOnClick, isActive}) =>{
    return(
        <div className="branch-card" onClick={handleOnClick} style={{backgroundColor:isActive? '#e9e9e9': "white"}}>
            <p className="title">{branch.name}</p>
            <p>{branch.location}</p>
            <p>{branch.phone}</p>
        </div>
    )
}

export default BranchCard