import React from 'react'
import ReactWOW from 'react-wow'

const OurTeam = () => {
	return (
		<section className="our-team">
			<div className="title-wrapper">
				<ReactWOW animation='fadeIn' duration="800ms">
					<p className="title">OUR TEAM</p>
				</ReactWOW>
				<ReactWOW animation='fadeInUp' duration="1200ms" >
					<p className="title-blurb">Meet our team of experts.</p>
				</ReactWOW>
			</div>
			<div className="our-team-grid">
				<div className="our-team-grid-item">
					<div className="image" style={{ backgroundImage: `url(${require('../../img/doctors/dr-boateng-dashen-opticians.jpg')})` }}></div>
					<div className="text-wrapper">
						<p className="name">Dr. Kwaku Boateng</p>
						<p className="position">Optometrist, Spintex Branch</p>
					</div>
				</div>
				<div className="our-team-grid-item">
					<div className="image" style={{ backgroundImage: `url(${require('../../img/doctors/dr-odua-dashen-opticians.jpg')})` }}></div>
					<div className="text-wrapper">
						<p className="name">Dr. Odua Adjei Boye</p>
						<p className="position">Optometrist, North Ridge Branch</p>
					</div>
				</div>
				<div className="our-team-grid-item">
                    <div className="image" style={{backgroundImage:`url(${require('../../img/doctors/dr-joyce-odame-dashen-opticians.jpeg')})`}}></div>
                    <div className="text-wrapper">
                        <p className="name">Dr. Joyce Odame</p>
                        <p className="position">East Legon Branch</p>
                    </div>
                </div>
				<div className="our-team-grid-item">
                    <div className="image" style={{backgroundImage:`url(${require('../../img/doctors/dr-fafa-amedor-dashen-opticians.jpeg')})`}}></div>
                    <div className="text-wrapper">
                        <p className="name">Dr. Fafa Amedor</p>
                        <p className="position">North Ridge Branch</p>
                    </div>
                </div>
			</div>
		</section>
	)
}

export default OurTeam;