import React from 'react'
import '../css/footer.css'
import {Link} from 'react-router-dom'
import {ABOUT, HOME, APPOINTMENTS, BRANCHES, LOGIN, CONTACT} from '../config/routes'

const Footer = () =>{ 
    return(
        <footer>
            <div className="footer-grid">
                <div className="grid-logo-item">
                    <img src={require('../img/logo-white.png')} className="logo" />
                </div>
                <div className="grid-item">
                    <Link to={HOME}>Our Offerings</Link>
                    <Link to={HOME}>Team</Link>
                    <Link to={APPOINTMENTS}>Book Appointment</Link>
                    <Link to={ABOUT}>About Us</Link>
                </div>
                <div className="grid-item">
                    <Link to={ABOUT}>Insurance Partners</Link>
                    <Link to={BRANCHES}>Branches</Link>
                    <Link to={CONTACT}>Contact</Link>
                    <Link to={LOGIN}>Log In</Link>
                </div>
                <div className="grid-item">
                    <p className="contact-title">CONTACT US</p>
                    <p>dashen_opticians@yahoo.com</p>
                    {/* <p>info@dashenopticians.com</p> */}
                    <p>0205842212</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer