import React, { useState, useEffect } from 'react'
import Header from '../components/Header'
import "../css/storescreen.css"
import "../css/storescreen-responsive.css"
import "../css/verify-payment-modal.css"
import "../css/payment-method-modal.css"
import StoreItem from '../components/store/StoreItem'
import Footer from '../components/Footer'
import PurchaseSummaryModal from '../components/store/PurchaseSummaryModal'
import VerifyPaymentModal from '../components/store/VerifyPaymentModal'
import PaymentMethodModal from '../components/store/PaymentMethodModal'
import Axios from 'axios'
import { useDispatch } from 'react-redux'
import { showMessage } from '../actions/actionCreators'
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';


const baseUrl = process.env.REACT_APP_BASE_URL;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		'& > *': {
			margin: theme.spacing(1),
		},
	},
}));

const StoreScreen = () => {

	const [isVisible, setIsVisible] = useState(false)
	const [products, setProducts] = useState([])
	const [searchQuery, setSearchQuery] = useState("");
	const [searchResults, setSearchResults] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [category, setCategory] = useState();

	const [selectedVariant, setSelectedVariant] = useState(null);
	const [isPaymentMethodModalVisible, setIsPaymentMethodModalVisible] = useState(false);

	const dispatch = useDispatch()

	const [selectedItem, setSelectedItem] = useState({
		id: 0,
		brand: "",
		name: "",
		price: 0
	})

	const getProducts = (category) => {
		setIsLoading(true);
		console.log("cateory", category);
		const query = category ? `?category=${category}` : "";

		Axios.get(baseUrl + '/api/inventory' + query)
			.then(results => {
				if (results.data) {
					console.log(results);
					setIsLoading(false)
					setProducts(results.data);
				} else {
					setIsLoading(false)
					console.log("error loading inventory items");
				}
			})
			.catch(err => {
				setIsLoading(false)
				dispatch(showMessage("Problem Loading Products. Please Refresh Page", "error"))
				console.log("errors", err)
			})
	}
	
	useEffect(() => {
		// const tx_ref = searchParams.get('tx_ref');
		// const status = searchParams.get('status');
		// const transaction_id = searchParams.get('transaction_id');
		// if(tx_ref && status && transaction_id){
		// 	setIsVerifyPaymentModalVisible(true)
		// }
		getProducts()
	}, [])

	const handleModalClose = () => {
		setIsVisible(false)
	}

	const initiatePayment = () =>{

	}

	// const handleVerifyPaymentClose = () =>{
	// 	setIsVerifyPaymentModalVisible(false)
	// }

	const handleCategoryChange = (category) => {
		setCategory(category);
		getProducts(category);
	}

	const handleItemClick = (item) => {
		setSelectedItem(item)
		setIsVisible(true)
	}


	const handleSearchChange = (e) => {
		setSearchQuery(e.target.value)

		const searchResults = products.filter(item => item.name.toLowerCase().includes(searchQuery.toLowerCase()))
		setSearchResults(searchResults)
	}

	const showStoreItems = () => {
		let items = [];

		if (searchQuery.length > 0) {
			items = searchResults
		} else {
			items = products;
		}

		// items = []


		if (isLoading) {
			return (
				<div>Loading products...</div>
			)
		} else if (items.length === 0) {
			return (
				<div>No Products</div>
			)
		} else {
			return (
				<div className="store-grid">
					{
						items.map((item, index) => (
							<StoreItem
								key={index}
								product={item}
								handleClick={() => handleItemClick(item)}
							/>
						))

					}
				</div>
			)
		}
	}




	return (
		<div className="storescreen" style={{minHeight:'100vh', paddingBottom:'350px', boxSizing:'border-box', position:'relative'}}>
			<Header />
			<div className="storescreen-wrapper">
				<PurchaseSummaryModal
					isVisible={isVisible}
					handleClose={handleModalClose}
					product={selectedItem}
					selectedVariant={selectedVariant}
					setSelectedVariant={setSelectedVariant}
					showPaymentMethodModal={()=>setIsPaymentMethodModalVisible(true)}
					/>
				<VerifyPaymentModal
					// isVisible={isVerifyPaymentModalVisible}
					// handleClose={handleVerifyPaymentClose}
					/>
				<PaymentMethodModal
					isVisible={isPaymentMethodModalVisible}
					selectedVariant={selectedVariant}
					handleClose={()=>setIsPaymentMethodModalVisible(false)}
					product={selectedItem}
				/>
				<div className="store-toolbar">
					<p className="title">Store</p>
					<div className="category-wrapper">
						<div>Category:</div>
						{/* <ButtonGroup classes={classes.buttonGroup} size="small" aria-label="small outlined button group">
							<Button>All</Button>
							<Button>Male</Button>
							<Button>Female</Button>
							<Button>Kids</Button>
						</ButtonGroup> */}
						<div className="category-list">
							<div onClick={() => handleCategoryChange()} className={`category-list-item ${category == undefined ? 'active' : ""}`}>All</div>
							<div className="category-divider"></div>
							<div onClick={() => handleCategoryChange('male')} className={`category-list-item ${category == 'male' ? 'active' : ""}`}>Male</div>
							<div className="category-divider"></div>
							<div onClick={() => handleCategoryChange('female')} className={`category-list-item ${category == 'female' ? 'active' : ""}`}>Female</div>
							<div className="category-divider"></div>
							<div onClick={() => handleCategoryChange('kids')} className={`category-list-item ${category == 'kids' ? 'active' : ""}`}>Kids</div>
						</div>
					</div>
					{/* <div className="search-wrapper">
						<input onChange={handleSearchChange} type="text" placeholder="Search For Item" />
					</div> */}
				</div>
				<div className="store-wrapper">
					{
						showStoreItems()
					}
				</div>


			</div>
			<Footer />
		</div>
	)
}

export default StoreScreen;