import initialState from './initialState'
import rootReducer from '../reducers/rootReducer'
import { applyMiddleware, compose, createStore } from 'redux';
import {persistReducer} from 'redux-persist';
import thunkMiddleware from 'redux-thunk';
import storage from 'redux-persist/lib/storage'

const persistConfig = {
    key: 'root',
    storage,
  }


const store = createStore(
    persistReducer(persistConfig, rootReducer),
    initialState,
    compose(
        applyMiddleware(thunkMiddleware)
        // ,window.__REDUX_DEVTOOLS_EXTENSION__()
    )
);

export default store;